html, body {
  background-color: white;
}

p {
  margin: 4px;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

