.English {
  font-family: 'Lora', serif;
  background-color: #e6e3e0;
  padding-top: 32px;
  padding-bottom: 64px;
}
.English .top-title {
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 32px;
}
.English .description {
  margin-bottom: 32px;
}
.English .youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 60px;
}
.English .youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
.English .HowItWorks {
  padding: 60px 0;
  background-color: black;
  color: white;
}
.English .HowItWorks .title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 32px;
}
.English .shoei-logo {
  margin-top: 64px;
  text-align: center;
  height: 64px;
}
.English .shoei-logo img {
  height: 100%;
}
